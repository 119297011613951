import * as API from '@/api/content'
import _ from 'lodash'
import Vue from 'vue'

const GRADES = [
  {
    name: '3-4 tuổi',
    oid: '617386776e6a26e9f787dd84',
    _id: 'T34'
  },
  {
    name: '4-5 tuổi',
    oid: '6173869dd43bce436624a1da',
    _id: 'T45'
  },
  {
    name: '5-6 tuổi',
    oid: '617386c40ded71dc272a4227',
    _id: 'T56'
  },
  {
    name: '6-7 tuổi',
    oid: '6220364a42a84f20050ec0a3',
    _id: 'T67'
  },
  {
    name: '7-8 tuổi',
    oid: '6220365b3b703fad064d9371',
    _id: 'T78'
  },
  {
    name: 'Lớp 1',
    oid: '5e80aecdf8cc1a401a91ab0b',
    _id: 'L1'
  },
  {
    name: 'Lớp 2',
    oid: '5e80aed552097b5fe5240b88',
    _id: 'L2'
  },
  {
    name: 'Lớp 3',
    oid: '5e80aedefcdf44e58025af8b',
    _id: 'L3'
  },
  {
    name: 'Lớp 4',
    oid: '5e80af4efcdf44b4ad25af8e',
    _id: 'L4'
  },
  {
    name: 'Lớp 5',
    oid: '5e80af56f8cc1a438691ab0f',
    _id: 'L5'
  },
  {
    name: 'Lớp 6',
    oid: '5e80aeecf8cc1a115391ab0c',
    _id: 'L6'
  },
  {
    name: 'Lớp 7',
    oid: '5e80af0bfcdf44b14f25af8c',
    _id: 'L7'
  },
  {
    name: 'Lớp 8',
    oid: '5e80af13f8cc1ad39791ab0d',
    _id: 'L8'
  },
  {
    name: 'Lớp 9',
    oid: '5e80af2152097bf58f240b89',
    _id: 'L9'
  },
  {
    name: 'Lớp 10',
    oid: '5e80af2afcdf447d9825af8d',
    _id: 'L10'
  },
  {
    name: 'Lớp 11',
    oid: '5e80af33f8cc1a49ec91ab0e',
    _id: 'L11'
  },
  {
    name: 'Lớp 12',
    oid: '5e80af3d52097be583240b8a',
    _id: 'L12'
  }
]

const WEEKS = Array(42)
  .fill()
  .map((_, index) => index + 1)

const YEARS = ['2023-2024', '2024-2025', '2025-2026', '2026-2027']

const SUBJECTS = [
  {
    _id: 'en',
    name: 'Tiếng Anh'
  },
  {
    _id: 'vi',
    name: 'Tiếng Việt'
  },
  {
    _id: 'math',
    name: 'Toán'
  }
]

const state = {
  school: null,
  schools: null,
  group: null,
  department: null,
  config: {},
  grades: GRADES,
  weeks: WEEKS,
  years: YEARS,
  subjects: SUBJECTS,
  classes: [],
  eClass: null,
  classSubmissions: [],
  mission: [],
  missionTemplate: [],
  students: [],
  sStudents: [],
  sLeads: [],
  homeworks: [],
  homework: null,
  teachers: [],
  lessons: [],
  lesson: null,
  easyforkids: {
    books: [],
    lessons: [],
    sections: [],
    testCategories: [],
    tests: [],
    conversationUnits: [],
    conversations: []
  },
  easyclass: {
    books: [],
    lessons: [],
    sections: [],
    testCategories: [],
    tests: [],
    conversationUnits: [],
    conversations: []
  },
  easycertificate: {
    books: [],
    lessons: [],
    sections: [],
    testCategories: [],
    tests: [],
    conversationUnits: [],
    conversations: []
  },
  classReport: {},
  notifications: [],
  news: [],
  newsPaginate: {
    total: 0,
    page: 1,
    limit: 10
  },
  meeting: null,
  leaderPlans: [],
  lessonPlans: [],
  teacherPlans: [],
  leaderPlan: null,
  lessonPlan: null,
  teacherPlan: null
}

const mutations = {
  SET_CONFIG: (state, data) => {
    state.config = data
  },
  SET_SCHOOL: (state, data) => {
    state.school = data
  },
  SET_SCHOOLS: (state, data) => {
    state.schools = data
  },
  SET_GROUP: (state, data) => {
    state.group = data
  },
  SET_DEPARTMENT: (state, data) => {
    state.department = data
  },
  SET_GRADES: (state, data) => {
    state.grades = data
  },
  SET_CLASSES: (state, data) => {
    state.classes = data
  },
  ADD_CLASS: (state, data) => {
    state.classes.unshift(data)
  },
  UPDATE_CLASS: (state, data) => {
    const index = _.findIndex(state.classes, (eClass) => {
      return eClass._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.classes.splice(index, 1, data)
    }
  },
  DELETE_CLASS: (state, data) => {
    const index = _.findIndex(state.classes, (eClass) => {
      return eClass._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      return state.classes.splice(index, 1)
    }
  },
  SET_CLASS: (state, data) => {
    state.eClass = data
  },
  SET_HOMEWORK_SUBMISSION: (state, data) => {
    state.classSubmissions = data
  },
  SET_MISSION: (state, data) => {
    state.mission = data
  },
  SET_MISSION_TEMPLATE: (state, data) => {
    state.missionTemplate = data
  },
  SET_STUDENTS: (state, data) => {
    state.students = data
  },
  SET_SCHOOL_STUDENTS: (state, data) => {
    state.sStudents = data
  },
  SET_SCHOOL_LEADS: (state, data) => {
    state.sLeads = data
  },
  APPROVE_JOIN_CLASS: (state, data) => {
    const index = _.findIndex(state.students, (student) => {
      return student._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      if (data._type === 'update') {
        Vue.set(state.students[index], 'status', data.status)
      } else {
        state.students.splice(index, 1)
      }
    }
  },
  UPDATE_STUDENT_INFO: (state, data) => {
    const index = _.findIndex(state.students, (student) => {
      return student._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.students[index] = data
      // Vue.set(state.students[index], 'status', data.status)
    }
  },
  SET_LESSONS: (state, data) => {
    state.lessons = data
  },
  CREATE_LESSON: (state, data) => {
    state.lessons.push(data)
    state.lessons.sort((a, b) => {
      return a.sortIndex > b.sortIndex ? 1 : -1
    })
  },
  UPDATE_LESSON: (state, data) => {
    const index = _.findIndex(state.lessons, (lesson) => {
      return lesson._id === data._id
    })

    if (index > -1) {
      Vue.set(state.lessons, index, data)
      Vue.set(state, 'lesson', data)
      state.lessons.sort((a, b) => {
        return a.sortIndex > b.sortIndex ? 1 : -1
      })
    }
  },
  DELETE_LESSON: (state, data) => {
    const index = _.findIndex(state.lessons, (lesson) => {
      return lesson._id === data._id
    })

    if (index > -1) {
      state.lessons.splice(index, 1)
    }
  },
  SET_LESSON: (state, data) => {
    state.lesson = data
  },
  SET_HOMEWORKS: (state, data) => {
    state.homeworks = data
  },
  SET_HOMEWORK: (state, data) => {
    state.homework = data
  },
  SET_EASYCLASS_DATA: (state, data) => {
    state.easyclass = data
  },
  SET_EASYCERTIFICATE_DATA: (state, data) => {
    state.easyclass = data
  },
  ADD_HOME_WORK: (state, data) => {
    state.homeworks.unshift(data)
  },
  UPDATE_HOME_WORK: (state, data) => {
    const index = _.findIndex(state.homeworks, (homework) => {
      return homework._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.homeworks.splice(index, 1, data)
    }
  },
  SET_CLASS_REPORT: (state, data) => {
    state.classReport = data
  },
  SET_NOTIFICATIONS: (state, data) => {
    state.notifications = data
  },
  SET_TEACHERS: (state, data) => {
    state.teachers = data
  },
  SET_NEWS: (state, response) => {
    state.news = response.data.news
    state.newsPaginate.total = Number(response.headers['x-pagination-total'])
    state.newsPaginate.page = Number(response.headers['x-pagination-page'])
    state.newsPaginate.limit = Number(response.headers['x-pagination-limit'])
  },
  SUBSCRIBE_STUDENT: (state, data) => {
    const index = _.findIndex(state.students, (student) => {
      return student._id.toString() === data.studentId.toString()
    })

    if (index !== -1) {
      Vue.set(state.students[index], 'subscribed', true)
    }
  },
  UNSUBSCRIBE_STUDENT: (state, data) => {
    const index = _.findIndex(state.students, (student) => {
      return student._id.toString() === data.studentId.toString()
    })

    if (index !== -1) {
      Vue.set(state.students[index], 'subscribed', false)
    }
  },
  SET_MEETING: (state, data) => {
    const index = _.findIndex(state.lessons, (lesson) => {
      return lesson._id.toString() === data.lessonId.toString()
    })

    if (index !== -1) {
      Vue.set(
        state.lessons,
        index,
        Object.assign(state.lessons[index], {
          meeting: data
        })
      )
    }
    state.meeting = data
  },
  SET_LEADER_PLANS: (state, data) => {
    state.leaderPlans = data
  },
  SET_LESSON_PLANS: (state, data) => {
    state.lessonPlans = data
  },
  SET_TEACHER_PLANS: (state, data) => {
    state.teacherPlans = data
  },
  CREATE_LEADER_PLAN: (state, data) => {
    state.leaderPlans.push(data)
    state.leaderPlans.sort((a, b) => {
      return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    })
  },
  UPDATE_LEADER_PLAN: (state, data) => {
    const index = _.findIndex(state.leaderPlans, (plan) => {
      return plan._id === data._id
    })

    if (index > -1) {
      Vue.set(state.leaderPlans, index, data)
    }
  },
  CREATE_LESSON_PLAN: (state, data) => {
    state.lessonPlans.push(data)
    state.lessonPlans.sort((a, b) => {
      return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    })
  },
  UPDATE_LESSON_PLAN: (state, data) => {
    const index = _.findIndex(state.lessonPlans, (plan) => {
      return plan._id === data._id
    })

    if (index > -1) {
      Vue.set(state.lessonPlans, index, data)
    }
  },
  CREATE_TEACHER_PLAN: (state, data) => {
    state.teacherPlans.push(data)
    state.teacherPlans.sort((a, b) => {
      return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    })
  },
  UPDATE_TEACHER_PLAN: (state, data) => {
    const index = _.findIndex(state.teacherPlans, (plan) => {
      return plan._id === data._id
    })

    if (index > -1) {
      Vue.set(state.teacherPlans, index, data)
    }
  },
  SET_LEADER_PLAN: (state, data) => {
    state.leaderPlan = data
  },
  SET_LESSON_PLAN: (state, data) => {
    state.lessonPlan = data
  },
  SET_TEACHER_PLAN: (state, data) => {
    state.teacherPlan = data
  }
}

const actions = {
  getConfig({ commit }) {
    return new Promise((resolve, reject) => {
      API.getSchoolConfig()
        .then((response) => {
          const config = response.data.config
          commit('SET_CONFIG', config)
          resolve()
        })
        .catch(reject)
    })
  },
  updateConfig({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateSchoolConfig(data)
        .then((response) => {
          commit('SET_CONFIG', data)
          resolve()
        })
        .catch(reject)
    })
  },
  getSchool({ commit }) {
    return new Promise((resolve, reject) => {
      API.getSchool()
        .then((response) => {
          const school = response.data.school
          commit('SET_SCHOOL', school)
          resolve(school)
        })
        .catch(reject)
    })
  },
  getSchools({ commit }) {
    return new Promise((resolve, reject) => {
      API.getSchoolNotDepartment()
        .then((response) => {
          const schools = response.data.schools
          commit('SET_SCHOOLS', schools)
          resolve(schools)
        })
        .catch(reject)
    })
  },
  getGroup({ commit }) {
    return new Promise((resolve, reject) => {
      API.getGroup()
        .then((response) => {
          const group = response.data.group
          commit('SET_GROUP', group)
          resolve(group)
        })
        .catch(reject)
    })
  },
  getDepartment({ commit }) {
    return new Promise((resolve, reject) => {
      API.getDepartment()
        .then((response) => {
          const department = response.data.departments
          commit('SET_DEPARTMENT', department)
          resolve(department)
        })
        .catch(reject)
    })
  },
  getGrades({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_GRADES', GRADES)
      resolve(GRADES)
    })
  },
  getClasses({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getClasses(data)
        .then((response) => {
          commit('SET_CLASSES', response.data.classes)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getValidContestClasses({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getValidContestClasses(data)
        .then((response) => {
          resolve(response)
        })
        .catch(reject)
    })
  },
  createClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createClass(data)
        .then((response) => {
          commit('ADD_CLASS', response.data.class)
          resolve(response.data.class)
        })
        .catch(reject)
    })
  },
  updateClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateClass(data)
        .then((response) => {
          commit('UPDATE_CLASS', response.data.class)
          resolve(response.data.class)
        })
        .catch(reject)
    })
  },
  getClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getClass(data)
        .then((response) => {
          commit('SET_CLASS', response.data.eClass)
          resolve(response.data.eClass)
        })
        .catch(reject)
    })
  },
  getHomeworkSubmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomeworkSubmission(data)
        .then((response) => {
          commit('SET_HOMEWORK_SUBMISSION', response.data.submissions)
          resolve(response.data.submissions)
        })
        .catch(reject)
    })
  },
  getMissionTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getMissionTemplate(data)
        .then((response) => {
          commit('SET_MISSION_TEMPLATE', response.data.missionTemplate)
          resolve(response.data.missionTemplate)
        })
        .catch(reject)
    })
  },
  getMission({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getMission(data)
        .then((response) => {
          commit('SET_MISSION', response.data.mission)
          resolve(response.data.mission)
        })
        .catch(reject)
    })
  },
  deleteClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteClass(data)
        .then((response) => {
          commit('DELETE_CLASS', data)
          resolve(true)
        })
        .catch(reject)
    })
  },
  getHomeworkComments({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomeworkComment(data)
        .then((response) => {
          resolve(response.data.comments)
        })
        .catch(reject)
    })
  },
  createHomeworkComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createHomeworkComment(data)
        .then((response) => {
          resolve(response.data.comment)
        })
        .catch(reject)
    })
  },
  updateHomeworkComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateHomeworkComment(data)
        .then((response) => {
          resolve(response.data.comment)
        })
        .catch(reject)
    })
  },
  deleteHomeworkComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteHomeworkComment(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  createFileHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = (data.keyTemps || [])
        .map((key) => key.value)
        .filter((key) => {
          return Boolean(key)
        })
      API.createFileHomework(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateFileHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps
        .map((key) => key.value)
        .filter((key) => {
          return Boolean(key)
        })
      API.updateFileHomework(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  createAttachmentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps
        .map((key) => key.value)
        .filter((key) => {
          return Boolean(key)
        })
      API.createAttachmentHomework(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateAttachmentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps
        .map((key) => key.value)
        .filter((key) => {
          return Boolean(key)
        })
      API.updateAttachmentHomework(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  createDocumentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = (data.keyTemps || [])
        .map((key) => key.value)
        .filter((key) => {
          return Boolean(key)
        })
      API.createDocumentHomework(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateDocumentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps
        .map((key) => key.value)
        .filter((key) => {
          return Boolean(key)
        })
      API.updateDocumentHomework(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },
  createMpHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createMpHomework(data)
        .then((response) => {
          commit('ADD_HOME_WORK', response.data.homework)
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  updateMpHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateMpHomework(data)
        .then((response) => {
          commit('UPDATE_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  createAppHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createEasyClassHomework(data)
        .then((response) => {
          commit('ADD_HOME_WORK', response.data.homework)
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  updateAppHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateEasyClassHomework(data)
        .then((response) => {
          commit('UPDATE_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  getUnistartData({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getUnistartData(data)
        .then((response) => {
          commit('SET_UNISTART_DATA', response.data.data)
          resolve(response.data.data)
        })
        .catch(reject)
    })
  },
  createUnistartAppHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createUnistartHomework(data)
        .then((response) => {
          commit('ADD_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateUnistartAppHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateUnistartHomework(data)
        .then((response) => {
          commit('UPDATE_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  createKidsAppHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createEasyForKidsHomework(data)
        .then((response) => {
          commit('ADD_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateKidsAppHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateEasyForKidsHomework(data)
        .then((response) => {
          commit('UPDATE_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  createQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createQuestionHomework(data)
        .then((response) => {
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  updateQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateQuestionHomework(data)
        .then((response) => {
          commit('SET_HOMEWORK', response.data.homework)
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  pushQGQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.pushQGQuestionHomework(data)
        .then((response) => {
          commit('SET_HOMEWORK', response.data.homework)
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  pullQGQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.pullQGQuestionHomework(data)
        .then((response) => {
          commit('SET_HOMEWORK', response.data.homework)
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  createCertificateHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createEasyCertificateHomework(data)
        .then((response) => {
          commit('ADD_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateCertificateHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateEasyCertificateHomework(data)
        .then((response) => {
          commit('UPDATE_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  getStudents({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getStudents(data)
        .then((response) => {
          commit('SET_STUDENTS', response.data.students)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getSchoolStudents({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getSchoolStudents(data)
        .then((response) => {
          commit('SET_SCHOOL_STUDENTS', response.data.students)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getSchoolLeads({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getSchoolStudents(data)
        .then((response) => {
          commit('SET_SCHOOL_LEADS', response.data.students)
          resolve(response)
        })
        .catch(reject)
    })
  },
  approveJoinClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.approveJoinClass(data)
        .then((response) => {
          commit('APPROVE_JOIN_CLASS', data)
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateStudentInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateStudentInfo(data)
        .then((response) => {
          commit('UPDATE_STUDENT_INFO', data)
          resolve(true)
        })
        .catch(reject)
    })
  },
  getHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomework(data)
        .then((response) => {
          // commit('SET_HOMEWORK', response.data.homework)
          resolve(response.data.homework)
        })
        .catch(reject)
    })
  },
  getEasyclassData({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getEasyclassData(data)
        .then((response) => {
          commit('SET_EASYCLASS_DATA', response.data.data)
          resolve(response.data.data)
        })
        .catch(reject)
    })
  },
  getNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getNotifications(data)
        .then((response) => {
          commit('SET_NOTIFICATIONS', response.data.notifications)
          resolve(true)
        })
        .catch(reject)
    })
  },
  getNews({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getNews(data)
        .then((response) => {
          commit('SET_NEWS', response)
          resolve(true)
        })
        .catch(reject)
    })
  },
  subscribeStudent({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.subscribeStudent(data)
        .then((response) => {
          commit('SUBSCRIBE_STUDENT', data)
          resolve(true)
        })
        .catch(reject)
    })
  },
  unsubscribeStudent({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.unsubscribeStudent(data)
        .then((response) => {
          commit('UNSUBSCRIBE_STUDENT', data)
          resolve(true)
        })
        .catch(reject)
    })
  },
  setLesson({ commit }, data) {
    commit('SET_LESSON', data)
  },
  getLessons({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLessons(data)
        .then((response) => {
          commit('SET_LESSONS', response.data.lessons)
          resolve(true)
        })
        .catch(reject)
    })
  },
  createLesson({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLesson(data)
        .then((response) => {
          commit('CREATE_LESSON', response.data.lesson)
          resolve(true)
        })
        .catch(reject)
    })
  },
  updateLesson({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateLesson(data)
        .then((response) => {
          commit('UPDATE_LESSON', response.data.lesson)
          resolve(true)
        })
        .catch(reject)
    })
  },
  deleteLesson({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteLesson(data)
        .then((response) => {
          commit('DELETE_LESSON', data)
          resolve(true)
        })
        .catch(reject)
    })
  },
  score({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.score(data)
        .then((response) => {
          commit('UPDATE_HOME_WORK', response.data.homework)
          resolve(true)
        })
        .catch(reject)
    })
  },
  getMeeting({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getMeeting(data)
        .then((response) => {
          commit('SET_MEETING', response.data.meeting)
          resolve(response.data.meeting)
        })
        .catch(reject)
    })
  },
  getLeaderPlans({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLeaderPlans(data)
        .then((response) => {
          commit('SET_LEADER_PLANS', response.data.plans)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getLeaderPlanHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLeaderPlanHistory(data)
        .then((response) => {
          resolve(response)
        })
        .catch(reject)
    })
  },
  getLessonPlans({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLessonPlans(data)
        .then((response) => {
          commit('SET_LESSON_PLANS', response.data.plans)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getTeacherPlans({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getTeacherPlans(data)
        .then((response) => {
          commit('SET_TEACHER_PLANS', response.data.plans)
          resolve(response)
        })
        .catch(reject)
    })
  },
  createLeaderPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLeaderPlan(data)
        .then((response) => {
          commit('CREATE_LEADER_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  updateLeaderPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateLeaderPlan(data)
        .then((response) => {
          commit('UPDATE_LEADER_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  createLessonPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLessonPlan(data)
        .then((response) => {
          commit('CREATE_LESSON_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  updateLessonPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateLessonPlan(data)
        .then((response) => {
          commit('UPDATE_LESSON_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  createTeacherPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createTeacherPlan(data)
        .then((response) => {
          commit('CREATE_TEACHER_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  updateTeacherPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateTeacherPlan(data)
        .then((response) => {
          commit('UPDATE_TEACHER_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  updateStatusLeaderPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateStatusLeaderPlan(data)
        .then((response) => {
          commit('UPDATE_LEADER_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  updateStatusLessonPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateStatusLessonPlan(data)
        .then((response) => {
          commit('UPDATE_LESSON_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  updateStatusTeacherPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateStatusTeacherPlan(data)
        .then((response) => {
          commit('UPDATE_TEACHER_PLAN', response.data.plan)
          resolve(response.data.plan)
        })
        .catch(reject)
    })
  },
  getLeaderPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLeaderPlan(data)
        .then((response) => {
          commit('SET_LEADER_PLAN', response.data.plan)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getLessonPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLessonPlan(data)
        .then((response) => {
          commit('SET_LESSON_PLAN', response.data.plan)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getTeacherPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getTeacherPlan(data)
        .then((response) => {
          commit('SET_TEACHER_PLAN', response.data.plan)
          resolve(response)
        })
        .catch(reject)
    })
  },
  getLiveExam({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLiveExam(data)
        .then((response) => {
          // commit('SET_HOMEWORK', response.data.homework)
          resolve(response.data.liveExam)
        })
        .catch(reject)
    })
  },
  createQuestionLiveExam({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createQuestionLiveExam(data)
        .then((response) => {
          resolve(response.data.liveExam)
        })
        .catch(reject)
    })
  },
  updateQuestionLiveExam({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateQuestionLiveExam(data)
        .then((response) => {
          // commit('SET_HOMEWORK', response.data.liveExam)
          resolve(response.data.liveExam)
        })
        .catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
