var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input-text",staticStyle:{"position":"relative","width":"100%","display":"flex","flex-wrap":"wrap"},style:(_vm.baseStyle)},[(_vm.label)?_c('div',{staticClass:"input-label",staticStyle:{"text-align":"left","margin-bottom":"8px","width":"100%"}},[_c('label',{staticStyle:{"color":"#333","font-weight":"bold","position":"relative"},style:('font-size: ' + (_vm.labelSize ? _vm.labelSize : 14) + 'px')},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):_vm._e(),(_vm.prefixIcon)?_c('md-icon',{staticStyle:{"position":"absolute","bottom":"10px","left":"10px"},style:(_vm.iconColor ? 'color: ' + _vm.iconColor : '')},[_vm._v(" "+_vm._s(_vm.prefixIcon)+" ")]):_vm._e(),_c('input',_vm._g(_vm._b({class:_vm.$style.input,style:((_vm.formType == 'filter' ? 'border-radius: 4px;' : ';') +
      ('padding-left: ' +
        (_vm.prefixIcon ? '35px' : '16px') +
        '; padding-right: ' +
        (_vm.suffixIcon ? '35px;' : '16px;')) +
      (_vm.disabled ? 'opacity: 0.5; cursor: not-allowed;' : '') +
      (_vm.backgroundColor
        ? `background-color: ${_vm.backgroundColor} !important`
        : '') +
      _vm.customStyle),attrs:{"disabled":_vm.disabled,"type":_vm.type,"placeholder":_vm.placeholder,"max":_vm.max},on:{"input":_vm.handleChange}},'input',_vm.$attrs,false),_vm.$listeners)),(_vm.suffixIcon)?_c('md-icon',{staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},style:(_vm.iconColor ? 'color: ' + _vm.iconColor : '')},[_vm._v(_vm._s(_vm.suffixIcon))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }