<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    formType: {
      type: String,
      default: 'text'
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    labelSize: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    max: {
      type: Number
    },
    customStyle: {
      type: String,
      default: ''
    },
    baseStyle: {
      type: String,
      default: ''
    },
    prefixIcon: {
      type: String,
      default: null
    },
    suffixIcon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: null
    },
    changeMethod: {
      type: Function
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('update', event.target.value)
    }
  }
}
</script>

<template>
  <div
    class="base-input-text"
    style="position: relative; width: 100%; display: flex; flex-wrap: wrap"
    :style="baseStyle"
  >
    <div
      v-if="label"
      class="input-label"
      style="text-align: left; margin-bottom: 8px; width: 100%"
    >
      <label
        style="color: #333; font-weight: bold; position: relative"
        :style="'font-size: ' + (labelSize ? labelSize : 14) + 'px'"
      >
        {{ label }}
      </label>
    </div>
    <md-icon
      v-if="prefixIcon"
      style="position: absolute; bottom: 10px; left: 10px"
      :style="iconColor ? 'color: ' + iconColor : ''"
    >
      {{ prefixIcon }}
    </md-icon>
    <input
      :disabled="disabled"
      :type="type"
      :class="$style.input"
      :placeholder="placeholder"
      :style="
        (formType == 'filter' ? 'border-radius: 4px;' : ';') +
        ('padding-left: ' +
          (prefixIcon ? '35px' : '16px') +
          '; padding-right: ' +
          (suffixIcon ? '35px;' : '16px;')) +
        (disabled ? 'opacity: 0.5; cursor: not-allowed;' : '') +
        (backgroundColor
          ? `background-color: ${backgroundColor} !important`
          : '') +
        customStyle
      "
      v-bind="$attrs"
      :max="max"
      v-on="$listeners"
      @input="handleChange"
    />
    <md-icon
      v-if="suffixIcon"
      style="position: absolute; bottom: 10px; right: 10px"
      :style="iconColor ? 'color: ' + iconColor : ''"
      >{{ suffixIcon }}</md-icon
    >
  </div>
</template>

<style lang="scss" module>
.input {
  background-color: #fff !important;
  // box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
  // border: 1px solid transparent !important;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin: 0 !important;
  width: 100% !important;
  height: 42px !important;
}
@media only screen and (max-width: 320px) {
}
@media only screen and (max-width: 480px) {
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 1024px) {
}
</style>
